import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    Inject,
    Renderer2
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {catchError, map, startWith} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {CreditCardValidators} from 'angular-cc-library';
import {RollbarService} from "../../assets/rollbar";
import Rollbar from 'rollbar';
import {environment} from '../../environments/environment';
import {COUNTRY} from '../components/consts/country.const';
import {STATE_US} from '../components/consts/state.const';

import {PayService} from '../components/service/pay.service';
import {LocalService} from "../components/service/local.service";

import Pusher from 'pusher-js';
import {MatomoTracker} from 'ngx-matomo';
import numeral from 'numeral';
import SignaturePad from 'signature_pad';
import {CARDS} from "../components/consts/cards.const";

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

    countries: any[] = [];
    filteredCountries: Observable<any[]>;
    states: any[] = [];

    authUser = false;
    showStateUs = false;
    paidSuccess = null;
    showTotalBalanceForm = false;
    showStatusSpinner = false;
    hideTryAgainButton = false;

    isLoadingAllForm = false;

    cards: any = CARDS;

    cardIcons: any = [
        {
            name: 'visa',
            path: 'assets/icons/visa.svg'
        },
        {
            name: 'mastercard',
            path: 'assets/icons/mastercard.svg'
        },
        {
            name: 'amex',
            path: 'assets/icons/amex.svg'
        },
        {
            name: 'unionpay',
            path: 'assets/icons/unionpay.svg'
        },
    ]

    params: any = {};

    pushInfo: any = {
        key: '7e30e7c146f87e5ee807',
        cluster: 'mt1',
        channel: 'events.', // + сгенерирований push_id,
        event: 'events.form'
    };
    push_id: string = '';
    paidStatusText: string = '';
    payRequestReturnUrl: string = '';

    configState = {
        isLogin: false,
        isUser: false,
        isSelectMethodPay: false,
        isCard: false,
        isSelectCard: false,
        isBilling: false,
        isResult: false,
        isPaidStatus: false,
        isPaySignature: false,
        isAcceptTerms: false
    };

    sendPayData: any = {};

    currentState = {
        title: '',
        path: ''
    };

    loading = false;

    user: any = {};
    form: any = {};
    userCards: any[] = [];
    card: any = {};
    method: any = {};
    publicConfig: any;

    userForm: FormGroup;
    loginForm: FormGroup;
    cardForm: FormGroup;
    billingForm: FormGroup;
    balanceForm: FormGroup;
    termForm: FormGroup;

    // signature
    @ViewChild('signature', {static: false}) signatureCanvas: HTMLCanvasElement;
    @ViewChild('signatureBlockForm', {static: false}) signatureBlock: HTMLCanvasElement;
    signaturePad: any;

    constructor(private fb: FormBuilder,
                public renderer: Renderer2,
                private route: ActivatedRoute,
                private payService: PayService,
                private localService: LocalService,
                private matomoTracker: MatomoTracker,
                private changeDetector: ChangeDetectorRef,
                @Inject(RollbarService) private rollbar: Rollbar) {
        this.localService.checkLocalStorage();
    }

    ngOnInit() {
        this.isLoadingAllForm = true;

        // необходи для проверки на место открытия формы (на сайте или отдельно в новой вкладке)
        if (environment.production) {
            if (window.self === window.top) {
                return;
            }
        }
        // this.isLoadingAllForm = false;
        // this.createBillingForm()
        // this.go('ibl', 'isBilling');

        // this.go('signature', 'isPaySignature');
        // this.initSignature();
        // return;
        this.getRouteParams();
    }

    getRouteParams(): void {
        this.route.queryParams.subscribe(res => {
            if (res && res.form) {
                if (res.authToken) {
                    this.params.authToken = res.authToken && res.authToken.length && res.authToken !== 'undefined' && res.authToken !== 'null' ? res.authToken : undefined;
                }
                if (res.total) {
                    this.params.total = numeral(+res.total).format('0,0.00');
                }
                if (res.logo) {
                    this.params.logo = (res.logo === 'true');
                }
                if (res.isComplete) {
                    this.params.isComplete = (res.isComplete === 'true');
                }
                if (res.client_id) {
                    this.localService.setItem(environment.clientId, res.client_id)
                }
                if (res.metadata) {
                    this.params.metadata = JSON.parse(res.metadata);
                    console.log(this.params.metadata);
                }
                this.params = {...res, ...this.params};
                this.getUrlConfig();
                this.subscribePusher();
            }
        });
    }

    getUrlConfig(): any {
        if (this.params.parentUrl) {
            this.payService.getUrlConfig(this.params.parentUrl).subscribe(res => {
                    if (res) {
                        this.checkConfig(this.params);
                    }
                },
                error => {
                    this.checkConfig(this.params);
                })
        } else {
            console.log('у конфиге нет - parentUrl')
            this.checkConfig(this.params);
        }

    }

    resizedContainer(event): void {
        const data = {
            height: event.newHeight,
            type: 'resize'
        };
        this.sendPostMessage(data);
    }

    checkConfig(params: any) {

        if (params.form) {
            this.getForm(+params.form);
        }

        if (params.isComplete) {
            this.isLoadingAllForm = false;
            if (params.PayerID && params.paymentId) {
                this.sendComplete();
            } else {
                this.showPaidStatus(false, 'Payment was canceled');
            }
            return this.changeState('isPaidStatus');
        }

        const isAuthToken = this.checkAuthToken(this.params);

        if (isAuthToken && params.auth != 'guest') {
            this.getUser();
        } else {
            this.checkAuthType(params.auth)
            this.removeToken();
        }


    }

    checkAuthType(type: string = 'all') {
        // только регистрация
        if (type === 'guest' || type === 'all') {
            this.createUserForm();
            return this.changeState('isUser');
        }

        // без токена, только логин
        if (type === 'login') {
            this.createLoginForm();
            this.changeState('isLogin');
        }

        // без токена предоставить выбор
        // if (type === 'all') {
        //     this.changeState('isUser')
        // }
    }

    loginGuest() {
        this.createUserForm();
        this.changeState('isUser');
    }

    subscribePusher() {

        this.push_id = Math.random().toString(16).substr(2, 8) + Math.random().toString(16).substr(2, 8);
        //console.log(this.push_id);
        const pusher = new Pusher(this.pushInfo.key, {
            cluster: this.pushInfo.cluster,
        });

        const channel = pusher.subscribe(this.pushInfo.channel + this.push_id);
        const that = this;
        channel.bind(this.pushInfo.event, function (this: any, data: any) {
            that.checkPaidStatus(data);
        });
    }

    checkPaidStatus(data: any) {
        console.log(data);
        this.loading = false;
        let isMessage = false;
        this.showStatusSpinner = false;
        this.hideTryAgainButton = false;
        if (data.message) {
            isMessage = true;
            this.paidStatusText = data.message;
        }

        if (data.type === 'token' && data.token != null) {
            this.sendPostMessage({
                type: 'addToken',
                data: {token: data.token}
            });
        }

        switch (data.status) {
            case  'paid':
                this.showPaidStatusSuccess(data, isMessage);
                break;
            case 'success':
                this.showPaidStatusSuccess(data, isMessage);
                break;
            case 'processing':
                this.paidStatusText = isMessage ? this.paidStatusText : 'Your payment Processing, yo will get notification soon';
                this.go('', 'isPaidStatus');
                break;
            case 'failed':
                this.paidStatusText = isMessage ? this.paidStatusText : 'Your Payment Failed. Please check payment information that you type';
                this.go('', 'isPaidStatus');
                break;
            case 'forbidden':
                this.paidStatusText = isMessage ? this.paidStatusText : 'Sorry your payment declined. Try later';
                this.go('', 'isPaidStatus');
                break;

            default:
                console.log('check paid status --error swich');
        }
    }

    showPaidStatusSuccess(data: any, isMessage: boolean) {
        this.sendPostMessage({
            type: 'successForm',
            data: {payment_id: data.id, id: this.form.id, name: this.form.name, total: this.form.total}
        });
        this.matomoTracker.trackEcommerceOrder(data.id, this.form.total);
        this.matomoTracker.trackPageView();
        if (this.params.success_url && this.params.success_url.length) {
            this.showPaidStatus('redirect', 'Redirecting to the site...');
            this.payRequestReturnUrl = this.params.success_url;
            this.go('', 'isPaidStatus');
            window.open(this.params.success_url, '_parent');
        } else {
            this.paidSuccess = true;
            this.paidStatusText = isMessage ? this.paidStatusText : 'Your payment successful';
            this.go('', 'isPaidStatus');
        }
    }

    checkAuthToken(params: any) {
        if (params.authToken && params.authToken !== 'undefined') {
            this.localService.setItem(environment.authTokenKey + params.client_id, params.authToken);
            return true;
        } else {
            // const token = this.localService.getItem(environment.authTokenKey + params.client_id);
            // return token && token.length;
            this.removeToken();
            return false;
        }
    }

    hasAuthToken() {
        const token = this.localService.getItem(environment.authTokenKey + this.params.client_id);
        return token && token.length;
    }

    removeToken() {
        this.authUser = false;
        this.localService.removeItem(environment.authTokenKey + this.params.client_id)
    }

    // User

    createUserForm() {
        this.userForm = this.fb.group({
            email: [this.params.email || '', Validators.compose([Validators.required, Validators.email])],
            first_name: [this.params.first_name || '', Validators.required],
            last_name: [this.params.last_name || '', Validators.required],
            id: [this.params.id || '']
        });
    }

    getUser() {
        this.createUserForm();
        this.payService.getUserByToken().subscribe(res => {
                if (res) {
                    this.user = res;
                    this.authUser = true;
                    this.userForm.get('id').setValue(this.user.id);
                    this.matomoTracker.setUserId(this.user.id);
                    this.changeState('isSelectMethodPay');
                } else {
                    this.removeToken();
                    this.checkAuthType(this.params.auth);
                }
            },
            error => {
                this.removeToken();
                this.checkAuthType(this.params.auth);
            });
    }

    saveUser() {
        const controls = this.userForm.controls;
        /** check form */
        if (this.userForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.user = this.userForm.value;
        this.changeState('isSelectMethodPay');
    }

    // Login

    createLoginForm() {
        this.loginForm = this.fb.group({
            username: [this.params.email || '', Validators.compose([Validators.required, Validators.email])],
            password: ['', Validators.required],
        });
    }

    changeTypePassword(passwordField: any): void {
        passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password';
    }

    login() {
        const controls = this.loginForm.controls;
        /** check form */
        if (this.loginForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return this.loading = false;
        }
        this.loading = true;
        const data = this.loginForm.value;
        this.payService.login(data).pipe(
            catchError(res => {
                const message = this.prepareServerError(res, this.loginForm, this.changeDetector);
                this.payService.openSnackBar(message, true);
                this.loading = false;
                return of(undefined);
            })
        ).subscribe(res => {
            if (res && res.access_token) {
                this.sendPostMessage({
                    type: 'addToken',
                    data: {token: res.access_token}
                });
                this.localService.setItem(environment.authTokenKey + this.localService.getItem(environment.clientId), res.access_token);
                this.getUser();
                this.changeState('isSelectMethodPay');
            }
            this.loading = false;
        });
    }


    // Form

    getForm(formId: number) {
        this.payService.getForm(formId).subscribe(res => {
            if (res) {
                this.form = res.data;
                this.sendPostMessage({
                    type: 'initForm',
                    data: {id: this.form.id, name: this.form.name, total: this.form.total}
                });
                this.matomoTracker.setEcommerceView(this.form.id, this.form.name, '', this.form.total);
                this.matomoTracker.trackPageView();
                this.isLoadingAllForm = false;
            }
        });
    }

    selectMethod(method: any) {
        this.method = method;
        if (this.form.is_balance) {
            if (this.params.total && this.params.total > 0) {
                this.showTotalBalanceForm = false;
            } else {
                this.showTotalBalanceForm = true;
            }
            this.balanceForm = this.fb.group({
                total: [this.params.total, [Validators.required, Validators.pattern('^[0-9]{1,100}$'),
                    Validators.min(1)]]
            });
        }
        if (method.type === 'creditcard') {
            this.createCardForm();
            if (!!this.hasAuthToken()) {
                this.getUserCards();
            }
            this.currentState.title = method.name;
            return this.changeState('isCard');
        } else if (method.type === 'balance') {
            if (this.user.balance < this.form.total) {
                return this.payService.openSnackBar('The amount of payment is more than you have on the balance', true);
            }
        }
        this.go('Result', 'isResult');
    }

    sendPostMessage(message) {
        parent.postMessage(JSON.stringify(message), this.params.parentDomain)
    }


    // Card

    createCardForm() {
        this.cardForm = this.fb.group({
            number: ['', [CreditCardValidators.validateCCNumber]],
            save: [''],
            expirationDate: ['', [CreditCardValidators.validateExpDate]],
            cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
            month: [''],
            year: ['']
        });
    }

    prepareCreditcardDate() {
        const date: string = this.cardForm.get('expirationDate').value;
        const split = date.split(' / ');
        this.card.month = split[0];
        this.card.year = split[1];

        if (this.card.expirationDate) {
            delete this.card.expirationDate;
        }
    }

    keyUpCCNumber(event): void {
        if (event.keyCode == 8 || event.keyCode == 46) {
            return;
        }
        const control = this.cardForm.get('number');
        if (control.valid) {
            this.focusElement('cc-exp-date');
        }
    }

    keyUPExpDate(event): void {
        const value = event.target.value;
        if (event.keyCode == 8 || event.keyCode == 46) {
            if (value === '' || value === null) {
                this.focusElement('cc-number');
            }
            return;
        }
        const control = this.cardForm.get('expirationDate');
        if (control.valid) {
            this.focusElement('cc-cvc');
        }
    }

    keyUpCvv(event: any, ccNumber: any) {
        const cvvValue = event.target.value;
        if (event.keyCode == 8 || event.keyCode == 46) {
            if (cvvValue === '' || cvvValue === null) {
                this.focusElement('cc-exp-date');
            }
            return;
        }
        ccNumber.resolvedScheme$.subscribe(res => {
            if (res && res != 'unknown' && cvvValue && cvvValue.length) {
                const card = this.cards.filter(item => item.type === res)[0];
                const isValidCvv = card.cvvLength.filter(val => val === cvvValue.length).length;
                if (!isValidCvv) {
                    this.cardForm.get('cvv').setErrors({invalid: true})
                }
            }
        })
    }

    focusElement(elementId: string): void {
        const element = document.getElementById(elementId) as HTMLElement;
        element.focus();
        this.changeDetector.markForCheck();
    }

    saveCard() {
        const controls = this.cardForm.controls;
        /** check form */
        if (this.cardForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }
        const cardValue = this.cardForm.value;
        cardValue.save = cardValue.save ? 1 : 0;
        this.card = cardValue;
        this.createBillingForm();
        this.go('Billing', 'isBilling');
    }

    getUserCards() {
        this.payService.getUserCards().subscribe(res => {
            if (res) {
                this.userCards = res.data;
            }
        });
    }

    selectCard(card) {
        this.card = card;
        this.go('Result', 'isResult');
    }


    // Billing

    createBillingForm() {
        this.prepareSelectorArray(COUNTRY, this.countries);
        this.billingForm = this.fb.group({
            billing_country_obj: [this.countries[0], Validators.required],
            billing_country: ['', Validators.required],
            billing_zipcode: ['', Validators.required],
            billing_address: ['', Validators.required],
            billing_city: ['', Validators.required],
            billing_state: ['', Validators.required],
        }, {
            validator: this.getBillingCountryError()
        });
        this.filteredCountries = this.billingForm.get('billing_country_obj').valueChanges
            .pipe(
                startWith(''),
                map(value => {
                    return this._filter(value)
                })
            );
        this.selectCountry(this.countries[0])
    }

    _filter(value: string | any): string[] {

        const filterValue = value && value.name ? value.name.toLowerCase() : value.toLowerCase();

        return this.countries.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    prepareSelectorArray(obj: any, array: any[]) {
        Object.keys(obj).map(key => {
            const item = {
                name: obj[key] + ' ' + key,
                code: key
            };
            array.push(item);
        });
    }

    selectCountry(value) {
        this.billingForm.get('billing_country').setValue(value.code);
        if (value.code === 'US') {
            this.showStateUs = true;
            this.billingForm.get('billing_state').setValue('');
            this.prepareSelectorArray(STATE_US, this.states);
        } else {
            this.billingForm.get('billing_state').setValue('');
            this.showStateUs = false;
        }
    }

    saveBilling() {
        const controls = this.billingForm.controls;
        /** check form */
        if (this.billingForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }
        this.card = {...this.card, ...this.billingForm.value};
        this.go('Result', 'isResult');
    }

    getBillingCountryError(): any {
        return (formGroup: FormGroup) => {
            const value = formGroup.get('billing_country_obj').value;
            if (typeof value === 'string') {
                formGroup.get('billing_country_obj').setErrors({mustObj: true})
            }
        }
    }


    // Pay

    pay(methodType: string) {
        this.matomoTracker.trackEvent('Form', 'Form payment method', methodType);
        this.matomoTracker.addEcommerceItem(this.form.id, this.form.name, '', parseFloat(this.form.total), 1);
        this.matomoTracker.trackPageView();
        this.loading = true;

        let data = {
            form_id: this.params.form,
            method_id: this.method.id,
            push_id: this.push_id,
            user: this.userForm.value,
        };
        if (this.form.is_balance && (!this.card || !this.card.id)) {
            const controls = this.balanceForm.controls;
            /** check form */
            if (this.balanceForm.invalid) {
                Object.keys(controls).forEach(controlName =>
                    controls[controlName].markAsTouched()
                );
                this.loading = false;
                return;
            }
            data = {...data, ...this.balanceForm.value};
        }

        if (methodType === 'creditcard') {
            if (this.card.id) {
                const card = {
                    creditcard: {
                        id: this.card.id
                    }
                };
                data = {...data, ...card};
            } else {
                this.prepareCreditcardDate();
                const card = {
                    creditcard: this.card,
                };
                data = {...data, ...card};
            }
        }
        // if (methodType === 'cryptocurrency' || methodType === 'paypal') {
            const urlObj = {
                returnUrl: this.params.returnUrl,
                cancelUrl: this.params.cancelUrl
            };
            data = {...data, ...urlObj};
        // }

        this.checkMetadata(data);

        this.sendPayData = data;
        this.payRequest(data, methodType);
    }

    checkMetadata(data: any): void {
        if (this.params.metadata && this.params.metadata.length) {
            this.params.metadata.map(value => {
                data.metadata = {...data.metadata, ...value};
            })
        }
    }

    payRequest(data: any, methodType: string) {
        this.showStatusSpinner = true;
        this.go('', 'isPaidStatus');
        this.payService.pay(data).pipe(
            catchError(res => {
                this.showStatusSpinner = false;
                this.checkPayRequestError(res);
                return of(undefined);
            })
        ).subscribe(res => {
            if (res) {
                this.sendPostMessage({
                    type: 'submitForm',
                    data: {id: this.form.id, name: this.form.name, total: this.form.total}
                });
                this.matomoTracker.trackEvent('Form', 'Submit form', this.form.name);
                this.matomoTracker.trackEcommerceCartUpdate(parseFloat(this.form.total));
                this.matomoTracker.trackPageView();
                // this.payService.openSnackBar(res.data.message, false);
                if (res.data.data && methodType === 'cryptocurrency' || methodType === 'paypal') {
                    this.showStatusSpinner = false;
                    this.paidSuccess = 'redirect';
                    this.payRequestReturnUrl = res.data.data.returnUrl;
                    this.paidStatusText = 'Redirecting to the site...';
                    window.open(res.data.data.returnUrl, '_parent');

                    this.loading = false;
                }
            }
        });
    }

    checkPayRequestError(res: any) {
        this.hideTryAgainButton = false;
        this.rollbar.error('angular test log ' + JSON.stringify(res));
        if (res && res.error && res.error.accept_terms) {
            this.payService.openSnackBar(res.error.accept_terms[0], true);
            this.initAcceptTerms();
            this.matomoTracker.trackEvent('Form', 'Form action', 'Terms part');
            this.sendPostMessage({
                type: 'FormError',
                data: {error: 'terms', id: this.form.id, name: this.form.name, total: this.form.total}
            });
            this.loading = false;
        } else if (res && res.error && res.error.signature) {
            this.go('Signature', 'isPaySignature');
            this.payService.openSnackBar(res.error.signature[0], true);
            this.initSignature();
            this.matomoTracker.trackEvent('Form', 'Form action', 'Signature part');
            this.sendPostMessage({
                type: 'FormError',
                data: {error: 'signature', id: this.form.id, name: this.form.name, total: this.form.total}
            });
            this.loading = false;
        } else {
            this.loading = false;
            this.hideTryAgainButton = true;
            this.showPaidStatus(false, res.error.message)
            this.payService.openSnackBar(res.error.message, true);
            this.rollbar.error('checkPayRequestError ' + JSON.stringify(res));
        }
    }

    sendComplete() {
        this.showStatusSpinner = true;
        const data = {
            p: this.params.p,
            PayerID: this.params.PayerID,
            paymentId: this.params.paymentId,
            token: this.params.token
        };

        this.payService.sendComplete(data).subscribe(res => {
            if (res) {
                this.checkPaidStatus(res);
            } else {
                this.showPaidStatus(false, 'Your transaction already marked like completed or cancelled. Feel free to contact support.');
            }
            this.showStatusSpinner = false;
            this.changeDetector.markForCheck();
        }, error => {
            this.showPaidStatus(false, 'Your transaction already marked like completed or cancelled. Feel free to contact support.');
            this.showStatusSpinner = false;
            this.changeDetector.markForCheck();
        });
    }

    tryAgain() {
        if (this.params.isComplete) {
            this.params.isComplete = false;
            this.checkConfig(this.params);
        } else {
            this.go('Result', 'isResult');
        }
    }


    // Signature

    // set default value in signature pad
    initSignature() {
        this.signatureCanvas['nativeElement'].width = this.signatureBlock['nativeElement'].clientWidth;
        this.signatureCanvas['nativeElement'].height = 140;
        this.signaturePad = new SignaturePad(this.signatureCanvas['nativeElement'], {
            backgroundColor: '#fafafa',
            penColor: 'rgb(0, 0, 0)'
        });
    }

    clearSignatureCanvas() {
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
    }

    saveSignature() {
        this.rollbar.info('clicked signature');
        if (!this.isSignaturePadEmpty()) {
            this.rollbar.info('clicked signature2');
            this.loading = true;
            this.sendPayData = {...this.sendPayData, ...{signature: this.signaturePad.toDataURL()}};
            this.payRequest(this.sendPayData, this.method.type);
        }
    }

    isSignaturePadEmpty(): boolean {
        return this.signaturePad && this.signaturePad.isEmpty();
    }


    // accept payment terms

    initAcceptTerms() {
        // this.termForm = this.fb.group({
        //     terms: ['', Validators.required],
        // });
        this.checkPublicConfig();
    }

    acceptTermsAfterScroll(event) {
        if (event) {
            // this.termForm.get('terms').setValue(true);
        }
    }

    saveTerms() {
        this.rollbar.info('clicked terms');
        this.loading = true;
        this.sendPayData = {...this.sendPayData, ...{accept_terms: true}};
        this.payRequest(this.sendPayData, this.method.type);
    }

    checkPublicConfig() {
        if (this.publicConfig && this.publicConfig.payment_terms_text) {
            return this.go('Accept Terms', 'isAcceptTerms');
        } else {
            this.payService.getPublicConfig().subscribe(res => {
                if (res && res.data) {
                    this.publicConfig = res.data;
                    this.go('Accept Terms', 'isAcceptTerms')
                }
            });
        }
    }


    // step by state

    changeState(selectField: string) {
        Object.keys(this.configState).map(field => {
            this.configState[field] = false;
        });

        if (selectField && selectField.length) {
            this.configState[selectField] = true;
        }
        this.changeDetector.detectChanges();
    }

    back() {
        if (this.configState.isUser) {
            this.createLoginForm();
            this.changeState('isLogin');
            this.removeToken();

        }
        if (this.configState.isSelectMethodPay) {
            if (this.params.auth === 'login') {
                this.createLoginForm();
                this.changeState('isLogin');
                this.removeToken();
            } else {
                this.changeState('isUser');
                this.removeToken();
            }
        } else if (this.configState.isCard) {
            this.changeState('isSelectMethodPay');
        } else if (this.configState.isBilling || this.configState.isSelectCard) {
            this.go('Credit Card', 'isCard');
        } else if (this.configState.isResult) {
            if (this.card && this.card.id) {
                this.go('Select Card', 'isSelectCard');
            } else if (this.method.type !== 'creditcard') {
                this.changeState('isSelectMethodPay');
            } else {
                this.go('Billing', 'isBilling');
            }
        } else if (this.configState.isPaySignature) {
            this.go('Accept Terms', 'isAcceptTerms')
        } else if (this.configState.isAcceptTerms) {
            this.go('Result', 'isResult')
        }
    }

    go(title: string = '', patch: string) {
        this.currentState.title = title;
        this.changeState(patch);
    }


    // show function

    showTopBar() {
        if (this.configState.isCard || this.configState.isBilling || this.configState.isSelectCard ||
            this.configState.isResult || this.configState.isPaySignature || this.configState.isAcceptTerms) {
            return true;
        }
        return false;
    }

    showPaidStatus(status: boolean | string, message: string) {
        this.paidSuccess = status;
        this.paidStatusText = message;
    }

    // showEditUserPanel() {
    //     if (this.configState.isSelectMethodPay) {
    //         //if (this.checkAuthToken(this.params)){
    //         return true;
    //         //}
    //     }
    //     return false;
    // }


    // show item name or item (если приходить строка)
    displayFn(item: any): string {
        return item && item.name ? item.name : item;
    }

    /**
     * Checking control validation
     *
     * @param form: => FormGroup
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(form: FormGroup, controlName: string, validationType: string): boolean {
        const control = form.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    prepareServerError(response: any, form: FormGroup, cdr: ChangeDetectorRef): any {
        if (!response || !response.error) {
            return console.log('!response.error');
        }
        let errors = response.error.errors;
        if (!errors && response.error.error && typeof response.error.error !== 'string') {
            errors = response.error.error;
        }
        if (errors) {
            Object.keys(errors).map(field => {
                let error = '';
                if (errors[field] && errors[field].length) {
                    errors[field].map(text => {
                        error += text;
                    });
                }
                form.controls[field].setErrors({'serverError': error});
            });
            // Mark for check
            cdr.markForCheck();
            return response.error.message ? response.error.message : 'The given data was invalid.';
        } else {
            // Show the error message
            let message = 'The given data was invalid.';
            if (response.error.error && typeof response.error.error === 'string') {
                message = response.error.error;
            } else if (response.error && response.error === 'string') {
                message = response.error;
            }
            return message;
        }
    }

    getErrorMessage(form: FormGroup, controlName: string, minLength: number = 3, maxlength: number = 320): any {
        const control = form.controls[controlName];
        if (control.hasError('minlength')) {
            return 'Minimum field length: ' + minLength;
        }
        if (control.hasError('maxlength')) {
            return 'Maximum field length: ' + maxlength;
        }
        if (control.hasError('email')) {
            return 'Not a valid email';
        }
        if (control.hasError('serverError')) {
            return control.getError('serverError');
        }
        return control.hasError('required') ? 'You must enter a value' : '';
    }


    getIconCardStyle(ccNumber: any, icon): any {
        var subject = new BehaviorSubject(null);
        ccNumber.resolvedScheme$.subscribe(res => {
            if (res && res.length && res != 'unknown') {
                const isThisCard = res === icon.name;
                const display = isThisCard ? 'block' : 'none';
                subject.next(display);
            } else {
                subject.next('block');
            }
        })
        return subject.getValue();
    }
}


