// Angular
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {LocalService} from "./local.service";

@Injectable()
export class InterceptService implements HttpInterceptor {
    
    constructor(private localService: LocalService) {
    }

    // intercept request and add token
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const headerConfig = {};

        if (this.localService.getItem(environment.clientId) && this.localService.getItem(environment.clientId).length) {
            headerConfig['x-api-key'] = this.localService.getItem(environment.clientId);
        }

        const userToken = this.localService.getItem(environment.authTokenKey + this.localService.getItem(environment.clientId));

        if (userToken) {
            headerConfig['Authorization'] = 'Bearer ' + userToken;
        }

        request = request.clone({
            setHeaders: headerConfig
        });

        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                    }
                },
                error => {
                }
            )
        );
    }
}
