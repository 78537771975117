import {Component, OnInit, AfterViewInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import { MatomoInjector, MatomoTracker } from 'ngx-matomo';
import {FormComponent} from './form/form.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  showModal = false ;

  constructor(private matomoTracker: MatomoTracker, private matomoInjector: MatomoInjector, private dialog: MatDialog) {
    this.matomoInjector.init(
        '//zenanalytics.net/',
        3
    );
  }

  ngOnInit(): void {
    if (this.showModal) {
      const dialogRef = this.dialog.open(FormComponent);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        console.log('norm');
      });
    }
    this.matomoTracker.setDocumentTitle('ZenPayment');
    this.matomoTracker.setDomains(['*.zenpayment.net']);
    this.matomoTracker.setCookieDomain('*.zenpayment.net');
  }

  /**
   * AfterViewInit lifecycle hook
   */
  ngAfterViewInit(): void {
    this.matomoTracker.trackPageView();
  }
}
