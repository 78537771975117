import {Injectable} from '@angular/core';

@Injectable()
export class LocalService {

    localData: any;
    isLocalStorage: boolean;

    checkLocalStorage() {
        try{
            this.isLocalStorage = !!localStorage;
        } catch (e){
            this.localData = {};
            this.isLocalStorage = false;
        }
        
    }

    setItem(path: string, data: any): void {
        this.isLocalStorage ? localStorage.setItem(path, data) : this.localData[path] = data;
    }

    getItem(path: string): any {
        return this.isLocalStorage ? localStorage.getItem(path) : this.localData[path];
    }

    removeItem(path): void {
        if (this.isLocalStorage) {
            if (localStorage.getItem(path)) {
                localStorage.removeItem(path)
            }
        } else {
            if (this.localData[path]) {
                delete this.localData[path];
            }
        }
    }

}
