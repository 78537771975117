import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material';

const localUrlConfig = {
    API_LOGIN_URL: environment.apiUrl + 'auth',
    API_USERS_URL: environment.apiUrl + 'me',
    API_FORM_API: environment.apiUrl + 'forms/',
    API_CREDITCARDS_API: environment.apiUrl + 'creditcards_preview',
    API_PAY_API: environment.apiUrl + 'pay',
    API_PAY_API_COMPLETE: environment.apiUrl + 'complete',
    API_PUBLIC_CONFIG: environment.apiUrl + 'site_public_config',
};

@Injectable()
export class PayService {

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    urlConfig: any = undefined;

    constructor(private http: HttpClient,
                private snackBar: MatSnackBar) {
    }

    openSnackBar(message: string, isError: boolean = false) {
        this.snackBar.open(message, '', {
            panelClass: isError ? 'snack-bar-error' : '',
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    getUrlConfig(urlConfig: string) {
        if (urlConfig && urlConfig.length) {
            return this.http.get<any>(urlConfig).pipe(map(res => {
                this.urlConfig = res;
                return res;
            }));
        } else {
            console.log('api url config - не найдено')
        }
    }

    getUrl(key: string) {
        let url = '';
        if (this.urlConfig) {
            Object.keys(this.urlConfig).map(res => {
                if (res === key) {
                    url = this.urlConfig[res];
                }
            })
        }
        if (!url || !url.length) {
            url = localUrlConfig[key];
        }
        return url;
    }

    // Authentication/Authorization
    login(data: any): Observable<any> {
        return this.http.post<any>(this.getUrl('API_LOGIN_URL'), data);
    }

    getUserByToken(): Observable<any> {
        return this.http.get<any>(this.getUrl('API_USERS_URL')).pipe(map(res => res.data ? res.data : res));
    }

    getForm(id: number) {
        return this.http.get<any>(this.getUrl('API_FORM_API') + id);
    }

    getUserCards() {
        return this.http.get<any>(this.getUrl('API_CREDITCARDS_API'));
    }

    pay(data) {
        return this.http.post(this.getUrl('API_PAY_API'), data);
    }

    sendComplete(data) {
        return this.http.post(this.getUrl('API_PAY_API_COMPLETE'), data)
            .pipe(
                map(res => res['data']),
                catchError(error => {
                    return throwError(error);
                })
            );
    }

    getPublicConfig(): any {
        return this.http.get(this.getUrl('API_PUBLIC_CONFIG'));
    }


    // /**
    //  * Prepare query http params
    //  * @param queryParams: QueryParamsModel
    //  */
    // getFindHTTPParams(queryParams): HttpParams {
    //   if (queryParams) {
    //     const params = new HttpParams({fromObject: queryParams});
    //     return params;
    //   }
    //   return new HttpParams();
    // }
}
