import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {NgModule, ErrorHandler, SkipSelf, Optional} from '@angular/core';

import {AppComponent} from './app.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule, MatDialogModule,
    MatIconModule,
    MatInputModule, MatListModule, MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule, MatSnackBarModule, MatTabsModule
} from '@angular/material';
import {FormComponent} from './form/form.component';
import {PayService} from './components/service/pay.service';
import {InterceptService} from './components/service/intercept.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {CreditCardDirectivesModule} from 'angular-cc-library';
import {LocalService} from './components/service/local.service';
import {CheckScrollDirective} from './components/directive/check-scroll.directive';
import {RollbarService, rollbarFactory, RollbarErrorHandler} from "../assets/rollbar";
import {MatomoModule} from 'ngx-matomo';
import {AngularResizedEventModule} from "angular-resize-event";
import {MatIconRegistry} from "@angular/material/icon";

@NgModule({
    declarations: [
        AppComponent,
        FormComponent,
        CheckScrollDirective
    ],
    entryComponents: [
        FormComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        MatomoModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([
            {path: 'pay-form', component: AppComponent},
            {path: '**', redirectTo: 'pay-forms', pathMatch: 'full'},
        ]),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        // angular material modules
        MatButtonModule,
        MatSelectModule,
        MatInputModule,
        MatRadioModule,
        MatIconModule,
        MatCardModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatDialogModule,
        MatListModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        CreditCardDirectivesModule,
        AngularResizedEventModule
    ],
    providers: [
        {provide: ErrorHandler, useClass: RollbarErrorHandler},
        {provide: RollbarService, useFactory: rollbarFactory},
        InterceptService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        PayService,
        LocalService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {


    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
        @Optional() @SkipSelf() parentModule?: AppModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }

        // Register icon sets
        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/material-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/material-outline.svg'));
    }
}
